@import './colors.scss';
@import './fonts.scss';
@import './App.scss';
@import './responsive.scss';
@import './arabic.scss';

body {
  margin: 0;
  font-family: 'Gilroy-regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App,
.main-container {
  height: 100%;
}

.white-bg {
  background-color: $white_bg;
}

.pink-bg {
  background-color: $pink_bg;
}

.text-danger {
  color: $danger-color;
}

.text-success {
  color: $success-color;
}

// toast
.Toastify__toast--success {
  background: $success-color !important;
}
.Toastify__toast--info {
  background: $info-color !important;
}
.Toastify__toast--warning {
  background: $warning-color !important;
}
.Toastify__toast--error {
  background: $danger-color !important;
}

.align-items-baseline {
  align-items: baseline;
}

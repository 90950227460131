.earnpoints-page{
    .blue-header{
        height: 240px;
        background-color: $blue_bg;
        z-index: -1;
        .pointspage-title{
            margin: 0;
            color: $light_txt;
            padding: 77px 112px 77px 35px;
            text-align: left;
        }
        .back-icon{
            position: absolute;
            left: 23.06px;
            top: 34.2px;
            cursor: pointer;
        }
    }
    .pointspage-content{
        margin-top: -51px;
        z-index: 1;
        padding: 0 35px 42px 35px;
        .pointspage-icon{
            margin-bottom: 20px;
        }
        .point-title, .point-txt{
            margin: 0;
        }
        .point-txt{
            margin-top: 15px;
        }
        .point-section{
            margin-bottom: 29px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
}
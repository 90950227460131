$blue_bg: #006782;
$white_bg: #ffffff;
$lightgrey-bg: #dfdfdf;
$light_txt: #ffffff;
$dark_txt: #000000;
$btn_bg: #0c697e;
$pink_bg: #e9b8a54d;
$grey_txt: #797979;
$darkgrey_txt: #4a5153;
$success-color: #22af47;
$info-color: #007bff;
$warning-color: #daa520;
$danger-color: #ff5370;
$disabled_btn: #d8d8d8;
$active_border: #e9b8a5;
$vip_bg1: #1d222a;
$vip_bg2: #262d39;
$vip_bg3: #39435a;

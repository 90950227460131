.blue-section {
  min-height: 327px;
}

.white-section {
  height: calc(50% - 88px);
}

.blue-section {
  background-color: $blue_bg;
  text-align: left;
  .home-title,
  .home-txt {
    margin: 0;
    color: $light_txt;
    text-align: left;
  }
  .home-title {
    padding: 30px 0px 0 40px;
  }
  .home-txt {
    padding: 25px 55.984px 140px 34.984px;
  }
}

.white-section {
  background-color: $white_bg;
  .white-section-content {
    margin-top: -20px;
  }
}
.points-expiry,
.points-history-section {
  padding: 0 20px;
  border-top: 1px solid $disabled_btn;
  border-bottom: 1px solid $disabled_btn;
  text-align: start;
  h2 {
    text-transform: capitalize;
  }

  .points-expiry-time {
    background: #ffedd0;
    border-radius: 6px;
    padding: 15px;
    line-height: 1.5;
    font-size: 15px;

    .expiry-time-color {
      color: #4a5153;
    }
    a {
      display: block;
      margin-top: 15px;
    }
  }
}

.points-history-section {
  border-bottom: none;
}
.points-section {
  margin-top: 20px;
  padding-bottom: 40px;
}

.vip-benefits-div {
    text-align: left;
    padding: 16px;

    .title {
        color: $darkgrey_txt;
        margin-top: 8px;
    }

    .description {
        color: $grey_txt;
        line-height: 1.5;
        margin: 16px 0;
    }

    .benefit-list {
        border-radius: 10px;
        padding: 15px;
        color: $light_txt;
        .benefit-list-title{
            line-height: 1.5;
        }
        .benefit-list-advantage{
            list-style-type: none;
            padding: 0;
            .list-item{
                line-height: 1.5;
                .star{
                    margin-inline-end: 5px;
                }
            }
        }
    }
}
@font-face {
    font-family: Gilroy-light;
    src: url(../fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: Gilroy-regular;
    src: url(../fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: Gilroy-medium;
    src: url(../fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: Gilroy-bold;
    src: url(../fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: Gilroy-heavy;
    src: url(../fonts/Gilroy/Gilroy-Heavy.ttf) format("truetype");
    font-weight: 900;
}

.light-txt{
    font-family:  "Gilroy-light", sans-serif;
}

.medium-txt{
    font-family:  "Gilroy-medium", sans-serif;
}

.bold-txt{
    font-family:  "Gilroy-bold", sans-serif;
}

.heavy-txt{
    font-family:  "Gilroy-heavy", sans-serif;
}

.sz-12{
    font-size: 12px;
}

.sz-13{
    font-size: 13px;
}

.sz-14{
    font-size: 14px;
}

.sz-16{
    font-size: 16px;
}

.sz-20{
    font-size: 20px;
}

.sz-24{
    font-size: 24px;
}

.sz-30{
    font-size: 30px;
}
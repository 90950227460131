.loyalty-btn{
  background-color: $blue_bg;
  color: $light_txt;
  width: calc(100% - 40px);
  margin:10px  20px 24px;
  border: 0px;
  border-radius: 5px;
  padding: 14.5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &.loading-btn{
    background-color: $white_bg;
  }
  &.disabled-btn{
    cursor: not-allowed;
    background-color: $disabled_btn;
  }
}
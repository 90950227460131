.pointshistory-div {
  .pointshistory-content {
    position: relative;
    text-align: start;
    min-height: 88px;
    .pointshistory-title {
      margin-bottom: 0;
      text-align: start;
      flex-grow: 1;
    }
    .pointshistory-link {
      text-align: end;
      padding-left: 2px;
      margin: 0;
      color: $btn_bg;
      cursor: pointer;
      flex-grow: 1;
    }
    .pointshistory-txt {
      color: $grey_txt;
    }
    .pointshistory-icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .d-flex {
    display: flex;
  }
}

.typetabs-wrapper{
  padding-top: 52px;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  justify-content: space-around;
  .typetab{
    padding: 10px;
    border-bottom: 2px solid $disabled_btn;
    color: $disabled_btn;
    width: 50%;
    cursor: pointer;
    &.active-typetab{
      border-bottom: 2px solid $active_border;
      color: $darkgrey_txt;
    }
  }
}
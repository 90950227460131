.balance-div {
  border-radius: 5px;
  width: calc(100% - 30px);
  margin: -120px 15px 40px 15px;
  z-index: 1;
  .balance-content {
    position: relative;
    padding: 9px 0px 13px 11px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .balance-title,
  .balance-points,
  .balance-txt {
    text-align: left;
    margin: 0;
  }
  .balance-title,
  .balance-txt {
    color: $grey_txt;
  }
  .balance-title {
    margin-bottom: 23px;
  }
  .balance-points:nth-child(2) {
    margin-bottom: 15px;
  }
  .balance-points:nth-child(3) {
    margin-bottom: 10px;
  }
  .balance-txt {
    margin-top: 13px;
  }
  .balance-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.overlay{
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #0000006b;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .congrats-div{
        margin: 0 29px;
        border-radius: 15px;
        padding: 259px 36px 30px 36px;
        position: relative;
        .congrats-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .congrats-title, .congrats-txt, .congrats-note{
                color: $darkgrey_txt;
                margin: 0;
            }
            .congrats-title{
                margin-bottom: 10px;
            }
            .congrats-txt{
                margin-bottom: 35px;
            }
            .congrats-icon{
                position: absolute;
                bottom: 183px;
                width: 96px;
                height: auto;
            }
        }
    }
}
.earnpoints-div{
    border-radius: 5px;
    padding: 16px 32px 12px 17px;
    width: calc(100% - 89px);
    margin: 45px 20px 0px 20px;
    .earnpoints-content{
        position: relative;
        text-align: left;
        min-height: 88px;
        .earnpoints-title{
            padding-bottom: 12px; 
            margin: 0;
            text-align: left;
            padding-right: 50px;
        }
        .earnpoints-link{
            text-align: left;
            padding-left: 2px;
            color: $btn_bg;
            cursor: pointer;
        }
        .earnpoints-icon{
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}
.vip-points-div {
  text-align: left;
  position: relative;
  bottom: -14px;
  margin-bottom: 10px;
  .balance-points {
    margin-bottom: 0 !important;
    padding-top: 10px;
    position: relative;
    .img{
      // position: absolute;
      // bottom: 0;
      padding: 0 3px;
    }
  }
  .no-balance-points{
    height: 5px;
  }

  .balance-content {
    border-radius: 10px;
    padding: 10px !important;
    position: relative;
    text-align: left;

  }

  .vip-list {
    list-style-type: none;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;

    .list-item {
      float: left;
      width: 48%;
      text-align: center;
      color: $grey_txt;
      &:first-child {
        border-inline-end: 1px solid $grey_txt;
      }

      label {
        display: block;
        margin-bottom: 10px;
      }
      .points-number{
        color: $dark_txt;
        font-family: Gilroy-bold;
        font-size: 18px;
      }
    }
  }
}


progress {
  width: 100%;
  height: 11px;
  border-radius: 100px;
  border: 1px solid #fff;
  overflow: hidden;
  background: radial-gradient(100% 14059.18% at 0% 57.14%, #DBAD4E 0%, #FFDA8D 100%);

}

progress::-moz-progress-bar {
  background: radial-gradient(100% 14059.18% at 0% 57.14%, #DBAD4E 0%, #FFDA8D 100%);
}

progress::-webkit-progress-value {
  background: radial-gradient(100% 14059.18% at 0% 57.14%, #DBAD4E 0%, #FFDA8D 100%);
}

progress::-webkit-progress-bar {
  background: linear-gradient(270deg, #B7B7B7 0%, #DDDDDD 100%);
}
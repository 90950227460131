.pointstransactions-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006b;
  z-index: 2;
}
.pointstransactions-div {
  text-align: start;
  .pointstransactions-header {
    background-color: $lightgrey-bg;
    padding-top: 10px;
    display: flex;
    align-items: center;

    .pointstransactions-icon-holder {
      width: 5%;
      padding-inline-start: 20px;

      .icon {
        fill: $blue_bg;
        cursor: pointer;
      }
    }

    .pointstransactions-header-title {
      text-align: center;
      width: 80%;
    }
  }
  .pointstransactions-content {
    position: fixed;
    width: 100%;
    z-index: 3;
    bottom: 0;
    left: 0;
    top: 15%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow-y: auto;

    .pointstransactions-transaction {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;

      .pointstransactions-title,
      .pointstransactions-txt,
      .pointstransactions-amount {
        margin: 0;
      }

      .pointstransactions-title {
        width: 70%;
      }
      .pointstransactions-amount {
        width: 30%;
        white-space: nowrap;
        text-align: end;
      }
      .pointstransactions-txt {
        width: 100%;
        color: $darkgrey_txt;
      }
      .pointstransactions-icon {
        position: absolute;
        bottom: 183px;
        width: 96px;
        height: auto;
      }
    }
  }
}
.transaction-empty {
  padding: 10vh 15px 15px;
  text-align: center;
}
.ar-app {
  .pointstransactions-div {
    .pointstransactions-header {
      .pointstransactions-icon-holder {
        .icon {
          transform: scaleX(-1);
        }
      }
    }
  }
}

.App {
  text-align: center;
  position: relative;
  .back-icon{
    position: absolute;
    left: 23.06px;
    top: 34.2px;
    cursor: pointer;
    fill: $white_bg;
    &.blue-back{
      fill: $blue_bg;
    }
  }
}
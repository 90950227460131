.vip-blue{
  background: linear-gradient(90deg, $vip_bg1 0%, $vip_bg2 40.95%, $vip_bg3 105%);
}
.blue-dark-section {
  min-height: 327px;

  .white-section {
    height: calc(50% - 88px);

  }
}
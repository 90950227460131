@font-face {
    font-family: Fedra-light;
    src: url(../fonts/Fedra/Fedra-Light.otf) format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: Fedra-regular;
    src: url(../fonts/Fedra/Fedra-Regular.otf) format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: Fedra-medium;
    src: url(../fonts/Fedra/Fedra-Medium.otf) format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: Fedra-bold;
    src: url(../fonts/Fedra/Fedra-Bold.otf) format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: Fedra-heavy;
    src: url(../fonts/Fedra/Fedra-Heavy.otf) format("opentype");
    font-weight: 900;
}

body {
    .ar-app{
        font-family: "Fedra-regular", sans-serif;
        *{
            direction: rtl;
        }
        .light-txt{
            font-family:  "Fedra-light", sans-serif;
        }

        .medium-txt{
            font-family:  "Fedra-medium", sans-serif;
        }

        .bold-txt{
            font-family:  "Fedra-bold", sans-serif;
        }

        .heavy-txt{
            font-family:  "Fedra-heavy", sans-serif;
        }
        .blue-section{
            text-align: right;
            .home-title{
                padding: 30px 40px 0 0px;
                text-align: right;
            }
            .home-txt {
                padding: 25px 34.984px 140px 55.984px;
                text-align: right;
            }
        }
        .balance-div{
            .balance-content{
                padding: 9px 11px 13px 0px;
            }
            .balance-icon{
                left: 0;
                right: unset;
                transform: scaleX(-1);
            }
            .balance-title, .balance-points, .balance-txt{
                text-align: right;
            }
        }
        .earnpoints-div{
            padding: 16px 17px 12px 32px;
            .earnpoints-content{
                text-align: right;
                .earnpoints-title{
                    text-align: right;
                    padding-right: 0px;
                    padding-left: 50px;
                }
                .earnpoints-link {
                    text-align: right;
                    padding-right: 0px;
                    padding-left: 2px;
                }
                .earnpoints-icon {
                    left: 0;
                    right: unset;
                    transform: scaleX(-1);
                }
            }
        }
        .earnpoints-page{
            .blue-header{
                .pointspage-title{
                    padding: 77px 35px 77px 112px;
                    text-align: right;
                }
                .back-icon {
                    left: unset;
                    right: 23.06px;
                    transform: scaleX(-1);
                }
            }
        }
        .vip-points-div{
            text-align: right;
            .balance-content{
                text-align: right;
            }
        }
        .vip-list{
            .list-item{
                float: right;
            }
        }
        .vip-benefits-div{
            text-align: right;
        }
        .points-expiry{
            text-align: right;
        }
    }
   
}

@media (max-width: 400px) {
    .earnpoints-page{
        .blue-header{
            .pointspage-title{
                padding: 77px 35px 77px 90px !important;
            }
        }
    }
}